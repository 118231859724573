/* =========================================
 callToAction Section 
 =========================================*/

#callToAction {
    background: url($image-background3) center center fixed;
    background-size: cover;
    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            background: transparent;
            border: 2px solid $color-white;
            padding: 12px 32px;
            font-size: 18px;
            font-weight: 400;
            &:hover,
            &:focus {
                background: $color-white;
                color: $color-black;
            }
        }
    }
    .list-inline {
        margin-bottom: 0;
    }
    
}