/* =========================================
 Video Section
 =========================================*/

#video {
    background: url($image-background2) fixed center center;
    background-size: cover;
    h1 {
        margin: 0;
        a {
            color: $color-white;
            line-height: 1.2;
           
            
            &:hover{
                i{
                     transform: scale(.9); 
                }
              
               
            }
        }
    }
}