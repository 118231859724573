/* =========================================
 Features Section  
 =========================================*/

#features {
    background: url(#{$image-background1}) center center no-repeat;
    background-size: cover;
    z-index: 1;
    .features-list {
        ul {
            margin-bottom: 0;
            li {
                margin-bottom: 20px;
                p.paragraph {
                    padding-top: 0;
                }
                .title {
                    font-size: 1.5em;
                    margin-bottom: 10px;
                    font-weight: 600;
                }
                .icon {
                    i {
                        border-radius: 50%;
                        position: relative;
                        padding: 17px 0;
                        color: $color-white;
                        width: 63px;
                        height: 63px;
                        border: 1px solid $color-white;
                        text-align: center;
                    }
                }
                &:hover {
                    .icon {
                        i {
                            @include swing();
                            box-shadow: $shadow-box;
                        }
                    }
                }
            }
        }
    }
    &.style2 {
        .features-list {
            ul {
                li {
                    .row {
                        direction: ltr;
                    }
                }
            }
        }
    }
    &:not(.style2) {
        .row {
            [class*="col"] {
                &:first-child {
                    p {
                        padding-right: 0;
                    }
                    .row {
                        direction: rtl;
                    }
                }
                &:last-child {
                    p {
                        padding-left: 0;
                    }
                }
                img {
                    width: 80%;
                }
            }
        }
    }
}