///*
// Mixins
//==========
// - CornerShaps  for corner section shaps
// ----- cornerShaps ---> by fontawsome code icons Ex: \f2dc  https://fontawesome.com/icons?d=gallery
// ----- cornerShapsSVG --->  by SVG backgrounds
// - Char Animations ( Animation from :https://daneden.github.io/animate.css/? )
// ----- charsAnimation --> for Hover swing Animation Ex: Xapp at Home page 
// ----- infiniteCharColors --> for chars text colors animation Ex: Loading text animation
// - svgBackgrounds for settings svg backgrounds
//  */ 
// Corner shaps by fontawsome 
@mixin cornerShaps($iconCode) {
    &:before,
    &:after {
        content: $iconCode;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        opacity: .2;
        font-size: 117px;
        position: absolute;
        color: $color-gray;
        &:hover {
            @extend %gradientText;
            transform: rotate(360deg);
        }
    }
    &:after {
        left: -30px;
        bottom: -57px;
    }
    &:before {
        right: -30px;
        top: -57px;
    }
}

//  By SVG images
@mixin cornerShapsSVG($svg) {
    &:before,
    &:after {
        content: '';
        opacity: .1;
        position: absolute;
        background: url($svg) center center no-repeat;
        background-size: cover;
        width: 230px;
        height: 230px;
        backface-visibility: hidden;
    }
    &:after {
        left: -30px;
        bottom: -57px;
        transform: rotate(180deg);
    }
    &:before {
        right: -30px;
        top: -57px;
    }
}

//   Chars Animation
@mixin charsAnimation ($loopTimes) {
    @for $i from 1 through $loopTimes {
        &:nth-child(#{$i}) {
            $delayTime: 100 * $i +ms;
            display: inline-block;
            @include pulse($delay: $delayTime); // From animation.css Animations
        }
    }
}

// Infinite Chars colors (Loading text color )
@mixin infiniteCharColors ($loopTimes) {
    @for $i from 1 through $loopTimes {
        &:nth-child(#{$i}) {
            $delayTime: 100 * $i +ms;
            display: inline-block;
            animation-name: charColor;
            animation-iteration-count: infinite;
            animation-duration: 1s;
            animation-delay: $delayTime;
            animation-timing-function: ease;
            animation-fill-mode: both;
            backface-visibility: hidden;
        }
    }
}


//For Svg Background 
@mixin svgBackgrounds($svg-background, $size) {
    background: url($svg-background) center no-repeat fixed;
    background-size: $size;
    background-position: right;
}