//Custom Colors 
$color-main: #dd3e54;
$color-secondary: #9733EE;
// colors 
$color-white:#fff;
$color-black:#27212b;
$color-gray :#7b7b7b;
$color-danger:#e83535;
$color-gradient:linear-gradient(to right, color(main), color(secondary));
$theme-colors:(main:$color-main, secondary:$color-secondary, gradient:$color-gradient);
// Box-shadow
$shadow-box:0 2px 5px 0 rgba(0, 0, 0, .16),
0 2px 5px 0 rgba(0, 0, 0, .23);
$shadow-box-top:0px -15px 14px 0 rgba(0, 0, 0, .25),
2px -13px 5px 0 rgba(0, 0, 0, .22);
$shadow-text:2px 4px 23px color(main);
// logo Fonts
$font-logo:'Quicksand',
sans-serif; 
$font-body:'Quicksand',
sans-serif;
$theme-fonts:(logo:$font-logo, body:$font-body);
// Background Images 
$image-background1:"../images/bg-1.jpg";
$image-background2:"../images/bg-2.jpg";
$image-background3:"../images/bg-3.jpg";
// Background SVG 
$svg-background-animated-chrome:"../svg/backgroundSvg-animated-chrome.svg";
$svg-background-animated-firefox:"../svg/backgroundSvg-animated-firefox.svg";
$svg-background2:"../svg/backgroundSvg-2.svg";