/* =========================================
 InnerPageTitle 
 =========================================*/

#innerPageTitle {
    position: relative;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
        z-index: 5;
    }
    /* tile of the page */
    .title {
        color: $color-white;
        -webkit-text-fill-color: $color-white;
        position: relative;
        margin: 0;
        padding: 20px 0;
    }
    /* ReferenceLinks */
    .breadcrumb {
        margin: auto;
        width: max-content;
        width: -moz-max-content;
        background: transparent;
        .breadcrumb-item {
            text-transform: capitalize;
            color: rgba($color-white, .6);
            font-weight: 500;
            a {
                @extend .breadcrumb-item;
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $color-white;
                }
            }
            &.active {
                color: $color-white;
            }
            &:before {
                color: rgba($color-white, .6);
            }
        }
    }
}