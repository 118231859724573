/* =========================================
 About section 
 =========================================*/

#about {
    box-shadow: $shadow-box-top;
    .about-features {
        margin-bottom: 0;
        li {
            margin-top: 20px;
            .title {
                text-transform: capitalize;
                margin: 20px 0 10px 0;
               
                h4{
                    font-weight: 800; 
                }
            }
            p.paragraph {
                margin-bottom: 0;
                font-size: 15px;
                color: $color-gray;
                font-weight: 500;
            }
            .icon {
                margin: 0px auto;
                display: block;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                background: $color-white;
                color: $color-black;
                width: 87px;
                height: 85px;
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    z-index: 2;
                    position: relative;
                }
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: color(gradient);
                    top: 0px;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    border-radius: 50%;
                }
                &:before {
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                }
                &:after {
                    background: $color-white;
                    width: 95%;
                    height: 95%;
                    z-index: 1;
                }
            }
            &:hover {
                .icon {
                    @include swing();
                    box-shadow: $shadow-box;
                }
            }
        }
    }
    &.style2 {
        .imgPanel {
            width: 80%;
            margin: 20px auto;
            max-width: 310px;
            img {
                width: 87%;
            }
        }
        .about-features li {
            >div {
                align-self: flex-start;
            }
            .icon {
                width: 65px;
                height: 65px;
            }
        }
    }
}

/*--------------------------------------*/