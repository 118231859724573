/*============================================================
Black Solid Demo   ClassName : black-solid-background-demo 
==============================================================*/

.black-solid-background-demo {
    /* Basic Rules */
    background-color: $color-black;
    .home section:nth-child(even) {
        @include cornerShapsSVG($svg-background2);
    }
    .mask {
        background: $color-black;
        opacity: .9;
    }
    /* Inner Page Title */
    #innerPageTitle {
        .mask {
            background: $color-black;
        }
    }
    /* Team section */
    #team {
        .mask {
            background: color(gradient);
        }
    }
    /* Loader*/
    #loader {
        background-color: $color-white;
    }
    /* Intro*/
    #intro {
        .fixed-section {
            background-color: $color-black;
            .jumbotron {
                p {
                    color: $color-gray;
                }
                .download-btns {
                    li:nth-child(3) {
                        .btn {
                            @extend .btn-transparent;
                        }
                    }
                    li:nth-child(2) .btn {
                        @extend .btn-white;
                    }
                }
            }
        }
    }
    /* Error Page */
    #errorPage {
        background-color: $color-black;
        color: $color-gray;
    }
    /* Coming Soon */
    #comingSoon {
        background-color: $color-black;
        color: $color-gray;
        #countDown>div p {
            color: $color-gray;
            strong {
                color: $color-gray;
            }
        }
    }
    /* Subscribe  section of coming soon and 404 Error pages */
    #comingSoon #subscribe,
    #errorPage #subscribe {
        color: $color-gray;
        .form-control {
            color: $color-white;
        }
    }
    /* socialMediaLinks  section of coming soon and 404 Error pages */
    #comingSoon .socialMediaLinks,
    #errorPage .socialMediaLinks {
        a {
            color: $color-gray;
            border-color: $color-gray;
        }
    }
    /* Footer  section of coming soon and 404 Error pages */
    #comingSoon+footer,
    #errorPage+footer {
        color: $color-gray;
    }
}