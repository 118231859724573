/* =========================================
 Loader 
 =========================================*/

#loader {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900000;
    overflow: hidden;
    background: $color-white;
    &,
    * {
        transition-timing-function: ease;
    }
    .progress {
        border-radius: 0;
        height: 3px;
        .progress-bar {
            background: color(gradient);
            box-shadow: $shadow-box;
        }
    }
    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        width: auto;
        height: 100px;
        color: $color-gray;
        text-transform: uppercase;
        span {
            @include infiniteCharColors(9);
            font-weight: 300;
            padding: 5px;
            vertical-align: middle;
        }
    }
    .snow {
        fill: #ccc;
    }
    .shap {
        color: color(main);
        opacity: .8;
        z-index: 99999;
    }
    &.hide {
        left: -150%;
    }
}

/* For Solid demos */

body[class*=solid] {
    #loader {
        .text {
            left: 90px;
            right: auto;
        }
    }
}