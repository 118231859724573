/* =========================================
 Basic Rules  
    - Variables
    - Transitions Effect
    - Change Selection Text Color
    - Scroll Bar style
    - Social Links styles
 =========================================*/

/* Variables */

:root {
    // each item in colors map
    @each $name,
    $color in $theme-colors {
        --color-#{$name}: #{$color};
    } //  each item in fonts map
    @each $name,
    $font in $theme-fonts {
        --font-#{$name}: #{$font};
    }
}

/* Transition Effect  */

*:not(#navbar):not(.tooltip),
::before,
::after {
    transition: all .5s cubic-bezier(.52, 1.64, .37, .66);
}

body {
    font-family: font(body);
    font-weight: 400;
    background: $color-white;
    font-size: 15px;
    line-height: 1.6;
    p {
        font-size: 1.1rem;
    }
    &[class*='black'] {
        // background: $color-black;
        #innerPageTitle+section {
            background: $color-white;
        }
    }
    main {
        position: relative;
        &.home .row {
            justify-content: center;
            align-items: center;
        }
    }
    * {
        line-height: 1.6;
    }
}

/*  Change Selection Text Color   */

::selection {
    background: color(main);
    opacity: .8;
    color: $color-white;
    -webkit-text-fill-color: $color-white;
}

::-moz-selection {
    background: color(main);
    opacity: .8;
    color: $color-white;
}

/*   Scroll Bar   */

*::-webkit-scrollbar {
    width: 7px;
}

*::-webkit-scrollbar-track {
    background-color: #eee
}

*::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    outline: 0px solid white;
    opacity: .6;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: $color-gray;
}

/* Social Links */

.socialMediaLinks {
    a {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $color-white;
        text-align: center;
        color: $color-white;
        font-size: 1.2rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        i {
            margin: 0;
            vertical-align: middle;
        }
        &:hover,
        &:focus {
            background: $color-white;
            text-decoration: none;
            @include swing();
            i {
                @extend %gradientText;
                @include swing();
            }
        }
    }
}

@keyframes charColor {
    0% {
        color: lighten($color-gray, 46%);
    }
    50% {
        color: color(main);
    }
    100% {
        color: lighten($color-gray, 46%);
    }
}