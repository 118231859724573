/* =========================================
 Intro 
 =========================================*/

#intro {
    min-height: 800px; // fixed - section 
    .fixed-section {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        .jumbotron {
            background: transparent;
            position: relative;
            height: 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            text-align: left;
            .logo {
                h1 {
                    margin: 0;
                    display: block;
                    span {
                        line-height: 1.2;
                    }
                }
            }
            p {
                margin: 40px auto 52px;
                display: block;
                font-size: 29px;
                font-weight: 400;
                text-transform: capitalize;
            }
            .imgPanel {
                width: 80%;
                margin: 20px auto;
                max-width: 310px;
                img {
                    width: 87%;
                }
            }
            .download-btns {
                margin-bottom: 40px;
                .btn {
                    margin-bottom: 20px
                }
            }
            .scroll-bottom {
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 5px;
                a {
                    background: transparent;
                    color: lighten($color-gray, 20%);
                    border: 1px solid lighten($color-gray, 40%);
                    border-radius: 50%;
                    font-size: 21px;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    text-align: center;
                    &:hover {
                        @include swing();
                        box-shadow: $shadow-box;
                        text-decoration: none;
                    }
                }
            }
        }
    } // Style 2
    &.style2 {
        .fixed-section {
            .jumbotron {
                text-align: center;
                p {
                    max-width: 640px;
                
                }
                .imgPanel {
                    width: 90%;
                    max-width: 563px;
                    margin: 40px auto;
                    img {
                        width: 100%;
                    }
                }
                .download-btns {
                    margin-bottom: 45px;
                }
            }
        }
    } // style 3
    #extra-info {
        >div {
            position: absolute;
            top: 45%;
            transform: rotate(-90deg) translateY(-42px);
            ul {
                margin-bottom: 0;
            }
            p {
                display: inline;
                color: darken($color-white, 20%);
                font-size: 13px;
                letter-spacing: 2px;
                span {
                    padding: 5px;
                }
            }
            a {
                color: lighten($color-gray, 20%);
            }
        }
        .left {
            left: 0px;
        }
        .right {
            right: 30px;
        }
        .statistics {
            .icon {
                vertical-align: middle;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                width: 30px;
                height: 30px;
                display: inline-block;
                margin: 0 5px;
                .mask {
                    opacity: 1;
                }
                i {
                    font-size: 13px;
                    padding: 2px;
                    position: relative;
                    color: $color-white;
                }
            }
        }
        .scroll-bottom {
            i {
                @extend %gradientText;
            }
        }
    } // Intro Slider of Style 3
    #intro-slider {
        .carousel-indicators {
            bottom: 0;
        }
    }
}