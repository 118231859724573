/* =========================================
 Subscribe Section
 =========================================*/

#subscribe {
    background: $color-black;
    color: $color-white;
    .input-group {
        margin: auto;
        .form-control {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            padding-left: 25px;
            font-weight: 500;
        }
    }
    /* Mailchimp */
    #mc_embed_signup {
        font-weight: 500;
        div.mce_inline_error {
            display: block;
            position: absolute;
            padding: 10px;
            top: 80px;
            width: 100%;
        }
    }
}

/* Subscribe  section of coming soon and 404 Error pages */

#comingSoon #subscribe,
#errorPage #subscribe {
    background: transparent;
    margin: 30px 0;
    color: $color-gray;
    p {
        text-transform: capitalize;
    }
    .input-group {
        width: 90%;
        box-shadow: $shadow-box;
        border-radius: 25px;
        background: rgba($color-white, .04);
        .form-control {
            background: transparent;
            border: 0;
            font-weight: 500;
       
        &:focus {
                border-width: 1px 0 1px 1px;
                background: $color-white;
                border-color: #ccc;
                outline: 0;
        }
        }
    }
    #mc_embed_signup {
        .response {
            border: 1px solid #ccc;
            padding: 12px 26px;
            background: #f7f6f6f0;
            width: auto;
            margin: auto;
            color: $color-black;
        }
        div.mce_inline_error {
            padding: 0px;
            top: 50px;
            width: 80%;
            margin: auto;
            left: 0;
            right: 0;
        }
    }
}