/* =========================================
 Screenshots Section
 =========================================*/

#screenshots {
    /* Slider */
    #screenshots-slider {
        .carousel-inner {
            .carousel-item {
                img{
                    margin: auto;

                }
                
            }
        }
        a[class*=carousel-control] span{
            @extend %gradientText;
            font-size: 50px;
        }
    }
}