/* =========================================
 Statistics Section  
 =========================================*/

#statistics {
    background: url(#{$image-background2}) center center no-repeat;
    background-size: cover;
    overflow: visible;
    p {
        margin-bottom: 0;
        &:not(.lead){
            margin-bottom: 13px;
            * {
                line-height: 1;
            }
        }
        span {
            font-size: 2.3rem;
            padding: 1px;
        }
        &.lead {
            text-transform: capitalize;
        }
    }
    img {
        position: absolute;
        top: 1750px;
        width: auto;
        height: 500px;
        bottom: 0;
        margin: auto;
        &.show {
            top: 34px
        }
    }
    .col-xl-6 {
        margin: 25px 0;
    }
}