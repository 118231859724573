/* =========================================
 Header 
 =========================================*/

body {
    &>#header {
        #navbar {
            box-shadow: $shadow-box;
            transition-timing-function: ease;
            .mask {
                z-index: -1;
                opacity: .9;
            }
            .navbar-brand.logo {
                font-weight: 400;
            }
            .navbar-dark .navbar-toggler {
                color: rgba(255, 255, 255, .8);
                outline: 0;
            }
            &:not(.scrolling) {
                box-shadow: none;
                .mask {
                    opacity: 0;
                }
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            font-weight: 500;
                        }
                    }
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        text-transform: capitalize;
                        color: rgba($color-white, .6);
                        font-weight: 500;
                        &.btn {
                            font-weight: 500;
                            background: $color-white;
                            color: $color-black;
                            border: 0px;
                            letter-spacing: 0;
                            i {
                                @extend %gradientText;
                            }
                            &:hover,
                            &:focus {
                                color: $color-black;
                            }
                        }
                    }
                    &:hover,
                    &:focus {
                        .nav-link {
                            color: rgba($color-white, .9);
                        }
                    }
                    &.show .nav-link,
                    .nav-link.active {
                        color: #fff;
                        background-color: transparent;
                    }
                }
            }
            .download-btns {
                .nav-item {
                    .nav-link {
                        color: $color-white;
                        &:hover,
                        &:focus {
                            i {
                                @include swing();
                            }
                        }
                    }
                }
            }
        }
    }
}

.dropdown {
    .dropdown-toggle::after {
        vertical-align: middle !important;
    }
    .dropdown-menu {
        * {
            text-transform: capitalize;
        }
        .dropdown-header {
            font-weight: 800;
        }
        .dropdown-item {
            font-size: .9rem;
            font-weight: 500;
            &.active,
            &:focus {
                background: color(main);
            }
            &:not(.active):hover {
                background: #e6e4e4;
            }
        }
        &.show {
            @include flipInX();
        }
    }
}