/* =========================================
 FQA  Section
 =========================================*/

#fqa {
    #accordion {
        .card {
            margin: 0.7rem 0;

            /* Question's Title (Header)*/
            .card-header {
                padding: 0;
                position: relative;

                cursor: pointer;
                overflow: hidden;
                text-transform: capitalize;

                h6 {
                    @extend %gradientText;
                    padding: .6rem 1.25rem;
                    font-size: 17px;

                }
            }

            /* Answer */
            .card-body {
                position: relative;

                p {
                    font-size: 14px;
                    color: $color-gray;
                    font-weight: 500;
                }
            }
        }
    }
}