/* =========================================
 Contact  Section 
 =========================================*/
 #contact {
    .contact-form{
        position: relative;
       
        .form-group{
            position: relative;
            .invalid-tooltip,.valid-tooltip{
                right:0;
            }
            
        }
    }

}
