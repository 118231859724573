/* =========================================
 Buttons 
 =========================================*/

.btn {
    background: color(gradient);
    color: $color-white;
    border-radius: 25px;
    min-width: 50px;
    border: 0px;
    position: relative;
    overflow: hidden;
    font-weight: 500;
    padding: 8px 18px !important;
    text-transform: capitalize;
    box-shadow: $shadow-box;
    font-size: 15px;
    i {
        margin: 0 7px;
        color: $color-white;
        -webkit-text-fill-color: $color-white;
        background-image: none;
        vertical-align: middle;
    }
    span {
        transition-duration: 0s !important;
        vertical-align: middle;
    }
    * {
        position: relative;
        z-index: 9;
    }
    &:hover,
    &:focus,
    &:active {
        background: $color-white;
        color: $color-black;
        i {
            @extend %gradientText;
        }
    }
    .input-group & {
        background: color(gradient);
        color: $color-white;
        &:hover,
        &:focus {
            background: $color-white;
            color: $color-black;
        }
    }
    .input-group-btn:not(:last-child) &,
    .input-group-btn:not(:first-child) &,
    .input-group-btn:not(:last-child) & {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        &:hover,
        &:focus {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }
}

/* Buttons styles*/

/* Button white */

.btn-white {
    background: $color-white;
    color: $color-black;
    i {
        background-image: color(gradient);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &:hover,
    &:focus {
        background: color(gradient);
        color: $color-white;
        i {
            color: $color-white;
            -webkit-text-fill-color: $color-white;
            background-image: none;
        }
    }
}

/* Black button */

.btn-black {
    background: $color-black;
    &:hover,
    &:focus {
        background: $color-white;
    }
}

/* Transparent button */

.btn-transparent {
    background: transparent;
    color: $color-white;
    border: 2px solid $color-white;
    i {
        color: $color-white;
        -webkit-text-fill-color: $color-white;
        background-image: none;
    }
    &:hover,
    &:focus,
    &:active {
        background: $color-white;
        color: $color-black;
        i {
            @extend %gradientText;
        }
    }
}

/* Cancel && close buttons */

section .btn.cancel,
section .btn.close,
section .btn[type="reset"] {
    background: #fff;
    color: $color-gray;
    &:hover,
    &:focus,
    &:active {
        background: $color-danger !important;
        color: $color-white;
    }
}

/* buttons */

.buttons {
    .btn {
        min-width: 126px;
    }
}