/* =========================================
 Helpers
    - .gradient-background -- > for Gradient Background
    - .gradient-text -- > for Gradient text
    - .logo -- > for Logo text ( font family ) 
    - .title , .section-title -- > for Title and Section title
    - .mask -- > for Mask
    - .paragraph --> for Paragraph
    - .zoomIn-img --> for Zooming In Images
    
 You can also use Bootstrap 4 Utilities : 
 https://www.w3schools.com/bootstrap4/bootstrap_utilities.asp     
 
 =========================================*/

/* Gradient Background */

.gradient-background {
    background: color(gradient);
}

/* Gradient text*/

.gradient-text {
    background-image: color(gradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Logo Text */

.logo {
    font-family: font(logo);
    h1 {
        // color: color(main);
        @extend %gradientText;
        font-size: 100px;
        font-weight: 400;
        text-align: center;
        position: relative;
        display: inline;
        span {
            // text-shadow: $shadow-text;
        }
        main:not(.home) &:hover {
            // span {
            //     @include charsAnimation(5);
            // }
            // -webkit-text-fill-color: color(main);
        }
    }
}

/* Title */

.title {
    text-transform: capitalize;
    margin-bottom: 30px;
    line-height: 1.6;
    p {
        text-transform: capitalize;
        font-size: 1.1rem;
        margin-bottom: 0;
        font-weight: 500;
    }
}

.section-title {
    @extend .title;
    margin-bottom: 70px;
    .display-4 {
        font-size: 3.5rem;
        margin-bottom: 15px;
        line-height: 1.1;
        text-transform: uppercase;
        letter-spacing: 4px;
    }
    p {
        font-size: 15px;
    }
}

/* Mask */

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(gradient);
    opacity: .85;
}

section>.mask {
    -webkit-backface-visibility: hidden;
}

/* Paragraph */

.paragraph {
    padding: 10px 0;
    font-size: 1.1rem;
    margin-bottom: 0;
}

/* Zoom In Images */

.zoomIn-img {
    img {
        cursor: zoom-in;
    }
}