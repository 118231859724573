// INDEX OF ATTENTION SEEKERS

@import 
  "_bounce",
  "_flash",
  "_jello",
  "_pulse",
  "_rubberBand",
  "_shake",
  "_swing",
  "_tada",
  "_wobble";
  