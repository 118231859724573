/* =========================================
 Team Section 
 =========================================*/

#team {
    .card {
        margin: 20px auto;
        height: 200px;
        width: 200px;
        overflow: hidden;
        perspective: 600px;
        position: relative;
        border-radius: 50%;
        background: transparent;
        padding: 5px;
        .faces {
            position: relative;
            transform-style: preserve-3d;
            height: 100%;
            display: block;
            >[class*=face] {
                position: absolute;
                backface-visibility: hidden;
                overflow: hidden;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                box-shadow: $shadow-box;
                border-radius: 50%;
            }
            .front-face {
                transform: rotateY(0);
                .overlay{
                    position:absolute;
                    left:0;
                    bottom:0;
                    width:100%;
                    h4{
                        position:relative;
                        color:$color-white;
                        font-size: 17px;
                        text-align: center;
                        padding: 9px 0 13px;
                        margin: 0;
                    }
                }
            }
            .back-face {
                transform: rotateY(180deg);
                opacity: .9;
                .card-body {
                    text-align: center;
                    background: color(gradient);
                    color: $color-white;
                    height: 100%;
                    .card-title {
                        margin-top: 21px;
                    }
                    a {
                        color: $color-white;
                        border-radius: 50%;
                        font-size: 19px;
                        &:hover,
                        &:focus {
                            i {
                                @include swing();
                            }
                        }
                    }
                }
            }
        }
        &:hover,
        &:focus {
          
            .faces {
                transform: rotateY(180deg);
            }
        }
    }
}