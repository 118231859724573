/* =========================================
 singleBlog 
 =========================================*/

#singleBlog {
    i {
        padding: 0 5px;
    }
    .userName {
        @extend %gradientText;
    }
    .card {
        margin-bottom: 40px; // post details 
        .post-details {
            padding: 40px 30px; // title of the post 
            .title {
                color: $color-black;
            } // date , tags and more
            .info {
                color: $color-gray;
                .tag {
                    .badge {
                        background: color(gradient);
                        border-radius: 50px;
                        color: $color-white;
                        padding: 7px 10px;
                    }
                }
                .author {
                    span {
                        padding: 0 5px;
                        text-transform: capitalize;
                        font-weight: 500;
                    }
                }
                .date {
                    text-transform: capitalize;
                    font-weight: 500;
                    i {
                        padding: 0 5px;
                    }
                }
            }
        } // post image 
        .post-image {
            .imgPanel {
                img {
                    width: 100% !important;
                }
            }
        } // post-content 
        .post-content {
            padding: 2.25rem;
            color: $color-black;
            font-size: 15px;
            line-height: 1.8;
            p {
                line-height: 1.8;
            } // Famous Words 
            .blockquote {
                position: relative;
                padding: 20px 30px;
                font-style: italic;
                color: $color-black;
                background: #e4e4e4;
                outline: 2px solid $color-white;
                outline-offset: 7px;
                font-size: 17px;
                line-height: 1.8;
            } // subTitle
            .subTitle {
                color: $color-black;
                margin: 20px 0;
            } // List 
            ul:not(.list-inline) {
                li {
                    position: relative;
                    list-style-type: none;
                    &:before {
                        content: "\f192";
                        font-family: "Font Awesome 5 Free";
                        color: color(main);
                        margin-right: 10px;
                    }
                }
            }
        }
    } // About Author
    .aboutAuthor {
        margin: 30px 0;
        .media {
            background-color: white;
            border: 1px solid #ced4da;
            border-radius: 5px;
            padding: 20px; // UserName 
            .userName {
                padding: 5px 0px 0 0;
                font-size: 1.4rem;
                text-transform: capitalize;
            }
            .media-heading {
                small {
                    color: $color-black;
                    font-size: .7em;
                    display: block;
                    text-transform: capitalize;
                    margin: 6px 0;
                    font-weight: 500;
                }
            } // User image 
            .userImage {
                height: 70px;
                width: 70px;
                border-radius: 50%;
                padding: 3px;
                border: 1px solid #ced4da;
                margin: 0 20px;
                img {
                    border-radius: 50%;
                }
            } // user comment
            .userComment {
                font-size: 15px;
                line-height: 1.7;
                font-weight: 500;
                color: $color-gray;
            }
        }
        .socialLinks {
            a {
                color: lighten( $color-gray, 20%);
                padding: 0 3px;
                i {
                    padding: 0;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: color(main);
                }
            }
        }
    } // Comments 
    #comments {
        &>div {
            margin: 30px 0; // comment 
            .comment {
                margin: 20px 0;
                .media {
                    background-color: white;
                    border: 1px solid #ced4da;
                    border-radius: 5px;
                    padding: 20px; // UserName 
                    .userName {
                        padding: 5px 0px 0 0;
                        font-size: 1.4rem;
                    } // Date 
                    .media-heading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        small {
                            color: lighten( $color-gray, 20%);
                            font-size: .5em;
                            span {
                                padding: 5px;
                                text-transform: capitalize;
                            }
                        }
                    } // User image 
                    .userImage {
                        height: 70px;
                        width: 70px;
                        border-radius: 50%;
                        padding: 3px;
                        border: 1px solid #ced4da;
                        margin: 0 20px;
                        img {
                            border-radius: 50%;
                        }
                    } // user comment
                    .userComment {
                        font-size: 14px;
                        line-height: 1.7;
                        font-weight: 500;
                    }
                }
                .commentActions {
                    a {
                        color: lighten( $color-gray, 20%);
                        padding: 0 3px;
                        i {
                            padding: 0;
                        }
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: color(main);
                        }
                    }
                }
                &.replay {
                    .media {
                        border-width: 1px 0 0 0;
                        border-radius: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}