/* =========================================
 Download Section 
 =========================================*/

#download {
    background: url($image-background3) center center fixed;
    background-size: cover;
    .download-links {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            padding: 12px 32px;
            font-size: 17px;
            font-weight: 500;
        }
    }
    .list-inline {
        margin-bottom: 0;
    }
   
}