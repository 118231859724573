/* =========================================
 404 Error
 =========================================*/

#errorPage {
    display: flex;
    justify-content: center;
    color: $color-black;
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .error-icon {
        color: color(main);
        font-size: 150px;
        font-weight: 400;
        text-align: center;
        position: relative;
        display: inline-block;
        border-radius: 50%;
        width: 310px;
        height: 310px;
        box-shadow: $shadow-box;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        background: rgba($color-white, .02);
        &:hover {
            span {
                @include charsAnimation(5);
            }
            -webkit-text-fill-color: color(main);
        }
    }
    h3 {
        font-weight: 400;
        margin: 30px 0;
    }
    p {
        font-weight: 700;
        display: block;
        margin: 25px 0;
        text-transform: uppercase;
        letter-spacing: 6px;
    }
}