/*============================================================
 Solid Demo   ClassName : solid-background-demo 
==============================================================*/

/* Basic Solid Style (SVG Animated Background */
body[class*=solid]{
    #intro.show .fixed-section .jumbotron::before 
    ,#errorPage::before,#comingSoon::before,  #loader:not(.hide)::before {

            content: '';
            @extend .mask;
            @include svgBackgrounds($svg-background-animated-chrome, cover);
            opacity: 1;
     
            }
   }
.solid-background-demo {
    /* Basic Rules */
    .home section:nth-child(even) {
        @include cornerShapsSVG($svg-background2);
    }
   
    /* Intro Section */
    #intro {
        .fixed-section {
            background-color: $color-white;
            p {
                color: $color-gray;
            }
            .download-btns {
                li:nth-child(3) {
                    .btn {
                        @extend .btn-black;
                    }
                }
                li:nth-child(2) .btn {
                    @extend .btn-white;
                }
            }
        }
        #extra-info {
            >div {
                .mask {
                    opacity: 1;
                }
                p {
                    color: $color-gray;
                    font-weight: 500;
                }
                a {
                    color: $color-gray;
                    font-weight: 500;
                }
            }
        }
       
    }
    /* 404Error Page */
    #errorPage {
        &.show {
           
        }
        color: $color-gray;
    }
    /* Coming Soon */
    #comingSoon {
       
        color: $color-gray;
        border-color: $color-gray;
        #countDown {
            >div {
                p {
                    color: $color-gray;
                }
            }
        }
    }
    /* Footer for 404Error , comingSoon pages */
    #comingSoon+footer,
    #errorPage+footer {
        color: $color-gray;
    }
    /* Subscribe  section of coming soon and 404 Error pages */
    #comingSoon #subscribe,
    #errorPage #subscribe {
        color: $color-gray;
        .form-control {
            color: $color-gray;
        }
    }
    /* socialMediaLinks  section of coming soon and 404 Error pages */
    #comingSoon .socialMediaLinks,
    #errorPage .socialMediaLinks {
        a {
            color: $color-gray;
            border-color: $color-gray;
        }
    }
}