/*!
 * Main styleSheet File for Default Demos
 * Project : Xapp - HTML App Landing Page Template
 * Version : 1.0
 * Author: Norhan_Moh
 * Primary use : Templates / Creative / showcase / Business /Mobile /App


 * Table Content :
  -----------------
    + VENDOR - Default fall-backs and external files.
    ------ Google Fonts
    ------ Bootstrap 4
    ------ Animate.scss
    ------ fontawesome 5

    + Global -  Variable file along with starting point Mixins and Placeholders
    ------ Variables
    ------ Placeholders
    ------ Mixins
    ------ functions

    + BASE - BasicRules , Helpers classes and Basic Pages Animations
 
    ------ BasicRules
    ------ Helpers
    ------ pagesAnimations

    + MODULES - Re-usable site elements; e.g. buttons ,lists ,tabs ,etc
    ------ Sections Styles 
    ------  Buttons 
    ------  Forms 
    ------  images 

    + LAYOUT - Contains styling for larger layout components; e.g. nav, header, footer,etc
    ------ Header 
    ------ Footer 

    + SECTIONS - Contains page-specific and sections styling
    ------  Intro -- > #intro
    ------  About -- > #about
    ------  Features -- > #features
    ------  Showcase -- > #showcase
    ------  Contact -- > #contact
    ------  Loader -- > #loader
    ------  Subscribe -- > #subscribe
    ------  Testimonials -- > #testimonials
    ------  Video -- > #video
    ------  Screenshots -- > #screenshots
    ------  Team -- > #team
    ------  Blogs -- > #blogs
    ------  Download -- > #download
    ------  CallToAction -- > #callToAction
    ------  Prices -- > #prices
    ------  FQA -- > #fqa
    ------  Contact Info  -- > #contactInfo
    ------  InnerPageTitle -- > #innerPageTitle
    ------  SingleBlog -- > #singleBlog
    ------  Error page -- > #errorPage
    ------  Statistics -- > #statistics
    ------  Blog Sidebar  -- > #blogSidebar
    ------  Coming Soon  -- > #comingSoon

   + Breakpoints - Contains responsive and breakpoints styling

   + Demos - Contains the styles of the demos ( Add the demo class to body tag)
    ------  Solid Demo (  SVG Background  )  -- > body.solid-background-demo 
    ------  Black Solid Demo (  SVG Background  )  -- > body.black-solid-background-demo 
    ------  Image Background Demo   -- > body.image-background-demo 


 * Vendor :
  ----------------
  + Google Fonts ( Quicksand font ) : https://fonts.google.com/specimen/Quicksand
  + Bootstrap v4.1.1 : http://getbootstrap.com
  + Animate.css v3.6.0 : http://daneden.me/animate
  + Animate.scss : https://github.com/geoffgraham/animate.scss
  + Font Awesome icons free v5.0.13 : https://fontawesome.com/icons?d=gallery&m=free

*/

/* VENDOR - Default fall-backs and external files.
========================================================================== */

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
@import "vendor/bootstrap/bootstrap";
@import "vendor/animate/animate";
@import "vendor/fontawesome/scss/fontawesome";
/* Global -  Variable file along with starting point Mixins and Placeholders
========================================================================== */

@import "global/functions";
@import "global/variables";
@import "global/placeholders";
@import "global/mixins";
/* BASE -  BasicRules , Helpers classes and Basic Pages Animations
========================================================================== */

@import "base/basicRules";
@import "base/helpers";
@import "base/pagesAnimation";
/* MODULES - Re-usable site elements; e.g. buttons ,lists ,tabs ,etc
========================================================================== */

@import "modules/sections";
@import "modules/buttons";
@import "modules/forms";
@import "modules/imagePanel";
@import "modules/modals";
/* LAYOUT - Contains styling for larger layout components; e.g. nav, header, footer,etc
========================================================================== */

@import "layout/header";
@import "layout/footer";
/* SECTIONS - Contains page-specific and sections styling
========================================================================== */

@import "sections/intro";
@import "sections/about";
@import "sections/features";
@import "sections/showcase";
@import "sections/contact";
@import "sections/loader";
@import "sections/subscribe";
@import "sections/testimonials";
@import "sections/video";
@import "sections/screenshots";
@import "sections/team";
@import "sections/blogs";
@import "sections/download";
@import "sections/callToAction";
@import "sections/prices";
@import "sections/fqa";
@import "sections/contactInfo";
@import "sections/innerPageTitle";
@import "sections/singleBlog";
@import "sections/statistics";
@import "sections/404Error";
@import "sections/blogSidebar";
@import "sections/comingSoon";
/* Demos - Contains the styles of the demos
========================================================================== */

@import "demos/black-solid-background";
@import "demos/solid-background";
@import "demos/image-background";
/* Breakpoints - Contains responsive and breakpoints styling
========================================================================== */

@import "breakpoints/breakpoints";