/*============================================================
Image Background Demo   ClassName : image-background-demo  
==============================================================*/

.image-background-demo {
    /* Basic Rules */
    #loader,
    .home section:nth-child(even) {
        @include cornerShaps('\f192');
    }
    /*   Intro section */
    #intro {
        background: url($image-background3) center no-repeat fixed;
        background-size: cover;
        .fixed-section {
            background: rgba($color-black, .95);
            .jumbotron {
                .logo h1{
                    color: lighten($color-gray, 30%);
                    -webkit-text-fill-color: lighten($color-gray, 30%);
                }
                p {
                    color: lighten($color-gray, 30%);
                }
                .download-btns {
                    li {
                        .btn {
                            @extend .btn-transparent;
                            color: lighten($color-gray, 30%);
                            border-color: lighten($color-gray, 30%);
                            &:hover,
                            &:focus {
                                border-color: $color-white;
                            }
                        }
                    }
                }
            }
            #extra-info>div a {
                color: darken($color-white, 20%);
            }
        }
    }
    /* Error page*/
    #errorPage {
        color: lighten($color-gray, 30%);
        background: url($image-background1) center no-repeat fixed;
        background-size: cover;
        &:before {
            content: '';
            @extend .mask;
            opacity: .95;
            background: $color-black;
        }
        .btn {
            @extend .btn-transparent;
            color: lighten($color-gray, 30%);
            border-color: lighten($color-gray, 30%);
            &:hover,
            &:focus {
                border-color: $color-white;
            }
        }
        .error-icon {
            background: rgba($color-white, .04);
        }
    }
    /* Coming Soon */
    #comingSoon {
        color: lighten($color-gray, 30%);
        background: url($image-background1) center no-repeat fixed;
        background-size: cover;
        &:before {
            content: '';
            @extend .mask;
            opacity: .95;
            background: $color-black;
        }
        #countDown {
            >div {
                p {
                    strong {
                        color: lighten($color-gray, 30%);
                    }
                    span {
                        color: lighten($color-gray, 20%);
                    }
                }
            }
        }
    }
    /* Inner page title */
    #innerPageTitle {
        background: url($image-background1) center no-repeat fixed;
        background-size: cover;
    }
    /* Footer for 404Error , comingSoon pages */
    #comingSoon+footer,
    #errorPage+footer {
        color: lighten($color-gray, 30%);
    }
    /* Subscribe  section of coming soon and 404 Error pages */
    #comingSoon #subscribe,
    #errorPage #subscribe {
        color: lighten($color-gray, 30%);
        .form-control {
            color: $color-white;
        }
    }
    /* socialMediaLinks  section of coming soon and 404 Error pages */
    #comingSoon .socialMediaLinks,
    #errorPage .socialMediaLinks {
        a {
            color: lighten($color-gray, 30%);
            border-color: lighten($color-gray, 30%);
            &:hover,
            &:focus {
                border-color: $color-white;
            }
        }
    }
}