/* =========================================
Coming Soon
 =========================================*/

#comingSoon {
    display: flex;
    justify-content: center;
    color: $color-black;
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    h3 {
        font-weight: 400;
    }
    p {
        display: block;
        font-weight: 500;
        font-size: 21px;
    }
    /* CountDown */
    #countDown {
        margin: 20px auto;
        >div {
            display: inline-block;
            margin: 10px;
            font-size: 15px;
            padding: 10px; // border: 1px solid #ccc;
            text-transform: uppercase;
            text-align: center;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            background: rgba($color-white, .04);
            box-shadow: $shadow-box;
            p {
                padding: 12px 0;
                font-weight: 900;
                margin: 0;
                font-size: 12px;
                color: $color-gray;
                strong {
                    display: block;
                    font-size: 3rem;
                    font-weight: 300;
                    line-height: 1.2;
                }
            }
        }
        &:hover {
            >div {
                @include charsAnimation(4);
            }
        }
    }
}