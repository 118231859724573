/* =========================================
 Images  
 =========================================*/

img {
    width: 100%;
}

.imgPanel {
    min-width: 50px;
    min-height: 50px;
    .home & {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: flex-end;
        img {
            margin: 0 -100px;
        }
        &:hover {
            img {
                margin: 0 -15px;
            }
        }
    }
    img {
        max-width: 100%;
        height: auto;
        position: relative;
        width: 100%;
        height: 100%;
        /* Broken Image */
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -10px;
            left: 0;
            height: 200px;
            width: 200px;
            background-color: rgb(230, 230, 230);
            border: 2px dotted rgb(200, 200, 200);
            border-radius: 5px;
            margin: auto;
        }
        &:after {
            content: "\f127" " Broken Image of " attr(alt);
            display: block;
            font-size: 16px;
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            color: #aaa;
            position: absolute;
            top: 45%;
            left: 0;
            width: 200px;
            text-align: center;
        }
    }
}