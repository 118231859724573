/* =========================================
 Blogs Section 
 =========================================*/

#blogs {
    backface-visibility: visible;
    .section-title {
        .display-4 {
            @extend %gradientText;
        }
        p {
            color: $color-gray;
        }
    }
    .blog-list {
        .card {
            position: relative;
            box-shadow: $shadow-box;
            margin-bottom: 20px;
            overflow: hidden;
            padding: 0;
            border-radius: 0;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                height: 100%;
                background: color(gradient);
                opacity: .7;
            }
            img[class*=card-img] {
                height: 250px;
            }
            .card-body {
                z-index: 1;
                background-color: #fff;
                .card-title {
                    color: $color-black;
                    font-weight: 800;
                    font-size: 16px;
                    margin-top: 20px;
                    a {
                        color: $color-black;
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: inherit;
                        }
                    }
                }
                .card-subtitle {
                    font-size: 14px;
                    font-weight: 800;
                    margin-bottom: 20px !important;
                    @extend .gradient-text;
                    span {
                        &:not(:first-child) {
                            opacity: 0;
                            margin: 0 10px;
                            &:before {
                                content: '|';
                                padding: 0 4px;
                                vertical-align: text-top;
                            }
                        }
                    }
                }
                .card-text {
                    color: $color-gray;
                    font-size: 15px;
                    margin-bottom: 25px;
                    font-weight: 500;
                }
                .btn {
                    margin-bottom: 25px;
                    @extend .btn-white;
                }
            }
            &:hover,
            &:focus {
                .card-body {
                    .card-subtitle {
                        span {
                            opacity: 1;
                            margin: 0;
                            transition: margin .2s ease;
                        }
                    }
                }
                &:before {
                    top: 0;
                }
            }
        }
    }
    .load-more {
        width: 140px;
        margin: 20px auto;
        display: block;
        text-align: center;
    }
    /* masonry-blogs */
    &.masonry-blogs {
        .blog-list {
            .card-columns {
                orphans: 1 !important;
                widows: 1 !important;
                .card {
                    display: inline-block;
                    *,
                    .btn * {
                        box-sizing: border-box;
                        overflow: initial;
                    }
                    &:before {
                        top: 0;
                        left: -110%;
                    }
                    &:nth-child(even) {
                        &:before {
                            top: 0;
                            left: 110%;
                        }
                    }
                    &:nth-child(4n+3) {
                        &:before {
                            top: 100%;
                            left: 0;
                        }
                    }
                    img[class*=card-img] {
                        height: inherit;
                        max-height: inherit;
                    }
                    .card-body {
                        position: relative;
                        z-index: none;
                    }
                    &:hover {
                        &:before {
                            left: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
        .col-xl-9 .card-columns {
            column-count: 2;
            .card {
                *,
                .btn * {
                    backface-visibility: hidden !important;
                }
            }
        }
    }
}

// Related-Blogs
#singleBlog+#blogs {
    background: #f5f5f5;
}