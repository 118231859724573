// INDEX OF FADING ENTRANCES

@import 
  "_fadeIn",
  "_fadeInDown",
  "_fadeInDownBig",
  "_fadeInLeft",
  "_fadeInLeftBig",
  "_fadeInRight",
  "_fadeInRightBig",
  "_fadeInUp",
  "_fadeInUpBig";
  