/* =========================================
 Footer 
 =========================================*/

body {
    &>footer {
        background: color(gradient);
        position: relative;
        color: $color-white;
        text-transform: capitalize;
        #top-btn {
            position: fixed;
            bottom: 10px;
            right: 30px;
            box-shadow: $shadow-box;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            z-index: 6;
            @include bounceOut();
            p {
                background: $color-white;
                border-radius: 50%;
                font-size: 2rem;
                text-align: center;
                i {
                    @extend %gradientText;
                }
            }
            &.show {
                @include bounceIn();
                &:hover,
                &:focus {
                    bottom: 25px;
                }
            }
        }
    }
}

/* Footer for 404Error , comingSoon pages */

#comingSoon+footer,
#errorPage+footer {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
    background: transparent;
    color: $color-gray;
    font-weight: 500;
}