/* =========================================
    Animated row
 =========================================*/

.image-background-demo {
    .home {
        #intro,
        #about:not(.style2),
        #features:not(.style2) {
            .container {
                .row {
                    >[class*=col-xl-4],
                    >[class*=col-xl-6],
                    >[class*=col-xl-3] {
                        @include slideOutDown();
                    }
                    &.show {
                        // 4 columns 
                        [class*=col-xl-3] {
                            @for $i from 1 through 4 {
                                &:nth-child(#{$i}) {
                                    $delayTime: 200 * $i +ms;
                                    @include slideInLeft($delay: $delayTime); // From animation.css Animations
                                }
                            }
                        } // 3 columns 
                        >[class*=col-xl-4] {
                            @include slideInUp();
                            &:first-child {
                                @include slideInLeft();
                            }
                            &:last-child {
                                @include slideInRight();
                            }
                        } // 2 columns 
                        >[class*=col-xl-6],
                        >[class*=col-xl-5] {
                            @include slideInUp();
                            display: block;
                            &:first-child {
                                @include slideInLeft();
                            }
                            &:last-child {
                                @include slideInRight();
                            }
                        }
                    }
                }
            }
        }
        #fqa {
            #accordion {
                >div {
                    @include slideOutDown();
                }
            }
            .show {
                #accordion {
                    >div {
                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                $delayTime: 200 * $i +ms;
                                @include slideInUp($delay: $delayTime); // From animation.css Animations
                            }
                        }
                    }
                }
            }
        }
        #contact {
            .contact-form {
                .form-group,
                button {
                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            $delayTime: 200 * $i +ms;
                            @include slideOutDown($delay: $delayTime); // From animation.css Animations
                        }
                    }
                }
            }
            .show {
                .contact-form {
                    .form-group,
                    button {
                        @for $i from 1 through 5 {
                            &:nth-child(#{$i}) {
                                $delayTime: 200 * $i +ms;
                                @include slideInUp($delay: $delayTime); // From animation.css Animations
                            }
                        }
                    }
                }
            }
        }
        #features.style2,
        #about.style2 {
            ul {
                li {
                    @include bounceOut();
                }
                &.show {
                    li {
                        @for $i from 1 through 6 {
                            &:nth-child(#{$i}) {
                                $delayTime: 200 * $i +ms;
                                @include bounceIn($delay: $delayTime); // From animation.css Animations
                            }
                        }
                    }
                }
            }
        }
        section:nth-child(even) {
            .row {
                >.col-xl-3,
                >.col-xl-4 {
                    @for $i from 1 through 4 {
                        &:nth-child(#{$i}) {
                            $delayTime: 200 * $i +ms;
                            @include slideOutRight($delay: $delayTime); // From animation.css Animations
                        }
                    }
                }
                &.show {
                    >.col-xl-3,
                    >.col-xl-4 {
                        @for $i from 1 through 4 {
                            &:nth-child(#{$i}) {
                                $delayTime: 200 * $i +ms;
                                @include slideInLeft($delay: $delayTime); // From animation.css Animations
                            }
                        }
                    }
                }
            }
        }
    }
}

main:not(.home) {
    section#blogs {
        .container {
            .row {
                [class*=col-xl-4],
                .card-columns .card {
                    @for $i from 1 through 15 {
                        &:nth-child(#{$i}) {
                            $delayTime: 100 * $i +ms;
                            @include slideOutDown($delay: $delayTime); // From animation.css Animations
                        }
                    }
                }
            }
        }
    }
    &.show {
        section#blogs {
            .container {
                .row {
                    // 3 columns 
                    [class*=col-xl-4],
                    .card-columns .card {
                        @for $i from 1 through 15 {
                            &:nth-child(#{$i}) {
                                $delayTime: 200 * $i +ms;
                                @include slideInUp($delay: $delayTime); // From animation.css Animations
                            }
                        }
                    }
                }
            }
        }
    }
}

main#comingSoon {
    #countDown {
        >div {
            @for $i from 1 through 4 {
                &:nth-child(#{$i}) {
                    $delayTime: 100 * $i +ms;
                    @include bounceOut($delay: $delayTime); // From animation.css Animations
                }
            }
        }
    }
    &.show {
        #countDown {
            >div {
                @for $i from 1 through 4 {
                    &:nth-child(#{$i}) {
                        $delayTime: 100 * $i +ms;
                        @include bounceIn($delay: $delayTime); // From animation.css Animations
                    }
                }
            }
        }
    }
}

main#errorPage {
    .error-icon {
        @include bounceOut(); // From animation.css Animations
    }
    &.show {
        .error-icon {
            @include bounceIn(); // From animation.css Animations
        }
    }
}