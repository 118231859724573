/* =========================================
 Modals 
 =========================================*/

#video-modal,
#image-modal {
    .fade {
        .modal-dialog {
            transition: transform 0.3s ease-out;
        }
    }
    .modal-dialog {
        max-height: 90%;
        box-shadow: none;
        margin-top: 0;
        .modal-content {
            background-color: transparent;
            padding: 0;
            border: 0px;
            .close {
                color: $color-white;
                margin-bottom: 30px;
            }
            img {
                margin: auto;
                display: block;
                width: 100%;
            }
        }
    }
}