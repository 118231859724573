/* =========================================
 Prices 
 =========================================*/

#prices {
    .plan {
        box-shadow: $shadow-box;
        border-radius: 25px;
        overflow: hidden;
        background: #fff;
        margin-bottom: 20px;
        ul {
            margin-bottom: 0;
            overflow: hidden;
            li {
                padding-top: 15px;
                padding-bottom: 15px;
                &:last-child {
                    padding: 0;
                    overflow: hidden;
                    .btn {
                        width: 100%;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                        background: $color-black;
                        box-shadow: none;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        opacity: .9;
                        margin-top: 15px;
                        &:hover,
                        &:focus,
                        &:active {
                            background: $color-black !important;
                            opacity: 1;
                            color: $color-white;
                        }
                    }
                }
                span {
                    padding: 0 5px;
                    text-transform: capitalize;
                    display: inline-block;
                }
                p {
                    margin-bottom: 0;
                    color: $color-gray;
                    font-weight: 500;
                    font-size: 16px;
                }
                b {
                    font-weight: 500;
                    color: $color-black;
                }
                /* Package's Title */
                &.title {
                    margin-bottom: 0;
                    background: $color-black;
                    p {
                        font-weight: bold;
                        color: #fff;
                        -webkit-text-fill-color: #fff;
                        text-transform: uppercase;
                        font-size: 19px;
                    }
                }
                /* Package's Price */
                &.price {
                    background: $color-white;
                    padding: 20px 0 10px;
                    sub {
                        font-size: .2em;
                        text-transform: capitalize;
                        font-weight: 500;
                    }
                    sup {
                        font-size: 0.4em;
                        font-weight: 100;
                        text-transform: uppercase;
                        vertical-align: super;
                    }
                    hr {
                        width: 70%;
                        margin: 0 auto;
                    }
                    * {
                        color: $color-black;
                    }
                }
            }
        }
        /* Recommended Package */
        &.recommended {
            ul {
                li {
                    &:last-child {
                        .btn {
                            background: color(gradient);
                            &:hover,
                            &:focus,
                            &:active {
                                background: color(gradient) !important;
                            }
                        }
                    }
                    &.price {
                        h2,
                        h2 * {
                            @extend %gradientText;
                        }
                    }
                    &.title {
                        background: color(gradient);
                    }
                }
            }
        }
        /* Hover */
        &:hover {
            transform: scale(1.05);
            .btn {
                opacity: 1 !important;
            }
        }
    }
}