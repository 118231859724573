/* =========================================
 Testimonials Section
 =========================================*/

#testimonials {
    background: url(#{$image-background1}) center center no-repeat fixed;
    background-size: cover;
    /* Slider */
    #testimonials-slider {
        .carousel-inner {
            .carousel-item {
                min-height: 160px;
                footer {
                    background: transparent;
                    color: $color-white;
                }
                .carousel-caption {
                    min-height: 160px;
                    top: 0;
                    width: 80%;
                    position: relative;
                    margin: auto;
                    right: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .carousel-indicators {
            position: relative;
            margin-top: 10px;
        }
    }
}