/* =========================================
  Blog Sidebar 
 =========================================*/

#blogSidebar {
    &>div {
        margin-bottom: 30px;
        &:not(:first-child) {
            border-top: 1px solid #ccc;
            padding-top: 30px;
        }
        &>h6.title {
            text-transform: uppercase;
            color: $color-black;
            font-weight: 900;
            margin-bottom: 20px;
        }
        &:hover,
        &:focus {
            &>h6.title {
                color: color(main);
            }
        }
    }
    .list-group-item {
        background: transparent;
        border: 0;
        padding: 5px 0;
        a {
            text-decoration: none;
            color: $color-gray;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 500;
            &:hover,
            &:focus {
                color: color(main);
            }
            p>a {
                font-weight: 500;
            }
        }
        .badge {
            background-color: transparent;
            font-weight: 400;
            a {
                font-weight: 500;
            }
        }
    } // latest Posts 
    .latestPosts {
        /* post */
        .post {
            margin-bottom: 20px;
            * {
                text-decoration: none;
            }
            h6.title {
                font-size: 13px;
                font-weight: 800;
                color: $color-black;
            }
            p {
                text-transform: capitalize;
                font-style: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                line-height: 1.4;
                font-size: 11px;
                color: $color-gray;
                font-weight: 500;
                margin: 0;
            }
            /* post Image */
            .imgPanel {
                height: 40px;
                width: 40px;
            }
            &:hover {
                h6.title {
                    color: color(secondary);
                }
            }
        }
    } // Search 
    .search {
        .form {
            .input-group {
                input.form-control {
                    border-radius: 30px;
                    padding: 9px 20px;
                    font-size: 14px;
                }
                .input-group-append {
                    position: absolute;
                    right: 0;
                    z-index: 14;
                    .btn {
                        box-shadow: none;
                        background: transparent;
                        border: 1px solid #ccc;
                        border-left: 0;
                        border: 0;
                        i {
                            @extend %gradientText;
                        }
                        &:hover,
                        &:focus {
                            background: color(gradient);
                            i {
                                color: $color-white;
                                -webkit-text-fill-color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    } // Archives , catrgories
    .archives,
    .categories {
        p {
            margin-bottom: 0;
            position: relative;
        }
    }
    .categories {
        .badge {
            background: $color-gray;
            margin: 0 8px;
            a {
                color: $color-white;
                font-size: 11px;
            }
        }
        a[data-toggle=collapse] {
            &:before {
                content: '+';
                position: absolute;
                right: 0;
                font-size: 20px;
                font-weight: 700;
            }
            &[aria-expanded=true] {
                &::before {
                    content: '-';
                }
            }
        }
        [id*=subCategory] {
            width: 100%;
            border-top: 1px solid #e0e0e0;
            padding: 5px 30px;
            margin: 11px 0;
        }
    } // Tags 
    .tags {
        a {
            text-decoration: none;
            border: 1px solid #ccc;
            margin: 2px;
            padding: 6px 15px;
            border-radius: 20px;
            display: inline-block;
            font-size: 12px;
            color: $color-gray;
            font-weight: 800;
            text-transform: capitalize;
            &:hover,
            &:focus,
            .active {
                background: color(gradient);
                color: #fff;
                border-color: $color-white;
            }
        }
    }
    .basic-widget {
        background: transparent;
        color: $color-gray;
        font-size: 13px;
        .card-title {
            color: $color-black;
            font-size: 15px;
            font-weight: 800;
        }
        .card-text {
            font-size: 13px;
            font-weight: 500;
        }
        .btn {
            font-size: 13px;
        }
    } // Follow Us 
    .follow {
        .socialMediaLinks {
            li {
                &:last-child,
                &:first-child,
                &:not(last-child) {
                    margin-right: 3px;
                }
                a {
                    color: lighten( $color-gray, 20%);
                    border: 1px solid lighten( $color-gray, 20%);
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    font-size: 15px;
                }
            }
        }
    }
}