/* =========================================
 Contact Info   Section 
 =========================================*/

#contactInfo {
    background: url($image-background2) center center fixed;
    background-size: cover;
    overflow: visible;
    ul {
        margin-bottom: 0;
        li {
            font-size: 1.6rem;
            font-weight: 500;
            i {
                margin: 0 10px;
            }
        }
        &.socialMediaLinks {
            li {
                i {
                    margin: 0;
                }
            }
        }
    }
}