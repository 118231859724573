/* =========================================
 Forms 
 =========================================*/

.form-messages {
    margin-top: 30px;
    margin-bottom: 30px;
    .alert {
        display: none;
    }
}

.form {
    .form-group {
        padding-right: 0;
        font-weight: 500;
    }.form-control {
    color: $color-black;
    font-weight: 500;
}
    input {
        &.form-control {
            border-radius: 50px;
            padding: 15px 30px;
        }
    }
    textarea {
        &.form-control {
            border-radius: 20px;
            padding: 25px 30px;
        }
    }
    &.was-validated {
        .form-control {
            border-bottom-right-radius: 0;
        }
    }
    label {
        @extend %gradientText;
        font-weight: 800;
    }
    label,
    div:not(.input-group-append)>.btn {
        margin: 11px;
        font-size: 1rem;
    }
    .input-group {
        width: 100%;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #b1b1b1 !important;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color:#b1b1b1 !important;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color:#b1b1b1 !important;
      }
      :-moz-placeholder { /* Firefox 18- */
        color:#b1b1b1 !important;
      }
}

.form-group .invalid-tooltip,
.form-group .valid-tooltip {
    right: 0;
}


