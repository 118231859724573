/* =========================================
 Sections  
 =========================================*/

section {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    backface-visibility: hidden;

    &:not(.small-section) {
        min-height: 700px;
        padding: 100px 10px;
    }

    &.small-section {
        min-height: 360px;
    }

    .home &:nth-child(odd):not(.even-section),
    .home &.odd-section,
    &.odd-section {
        color: $color-white;

        .title,
        .section-title {

            .display-4,
            p {
                color: $color-white;
                -webkit-text-fill-color: $color-white;
            }
        }
    }

    .home &:nth-child(even):not(.odd-section),
    .home &.even-section,
    &.even-section {
        color: $color-black;
        background: $color-white;

        .title,
        .section-title {

            .display-4,
            h4 {
                @extend %gradientText;
            }

            p {
                color: $color-gray;
                -webkit-text-fill-color: $color-gray;
            }
        }
    }

    &.even-section+&.even-section {
        padding-top: 0px;

        .solid-background-demo .home &:before {
            display: none;
        }
    }
}