/* =========================================
 Breakpoints / Responsive Screens 

    = Breakpoint viewport sizes and media queries.
    = Breakpoints are defined as a map of (name: minimum width), order from small to large:
    = (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

 =========================================*/

/*  Extra large devices (large desktops, 1200px and up) */

@include media-breakpoint-up(xl) {
    // Download & call To Action
    #download,
    #callToAction {
        .section-title {
            margin-bottom: 0;
        }
    } //Intro
    #intro {
        min-height: 1024px;
        .fixed-section {
            .jumbotron {
                .container {
                    max-width: 80%;
                }
            }
        }
    }
}

/* Screens Between 1440px and 1199.98px ( max-width:1200px & min-width:992px )*/

@include media-breakpoint-between(lg, xl) {
    //  Blogs Section 
    #blog {
        .blog-list {
            .card-title {
                font-size: 1.2rem;
            }
            .card-subtitle {
                font-size: .8rem;
            }
        }
    } // Download 
    #download {
        .section-title {
            .display-4 {
                font-size: 3.1rem;
            }
        }
    } // Small Section 
    .small-section {
        .section-title {
            margin-bottom: 30px;
        }
    } // subscribe 
    #subscribe {
        form {
            margin-top: 30px;
        }
    } // Intro 
    #intro {
        min-height: 950px;
        .fixed-section {
            .jumbotron {
                .logo {
                    h1 {
                        font-size: 100px;
                    }
                }
            }
        }
    }
}

/*  Large devices (desktops, 992px and up) */

@include media-breakpoint-up(lg) {
    // Header 
    #header {
        #navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link.active {
                        @include swing();
                    }
                }
            }
        }
    } // subscribe
    #subscribe {
        .input-group {
            width: 50%;
            margin: auto;
            height: 50px;
        }
    } //Intro 
    #intro {
        //Style 1
        &:not(.style2):not(.style3) {
            .fixed-section {
                .jumbotron {
                    .logo {
                        h1 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    } // Showcase 
    #showcase {
        .showcase-list {
            .row {
                .imgPanel {
                    max-width: 50%;
                    margin: auto;
                }
            }
        }
    } // Features 
    #features {
        &:not(.style2) {
            .features-list {
                .row {
                    [class*="col"] {
                        ul {
                            // margin-top: 30px;
                            li {
                                &:first-child,
                                &:last-child {
                                    margin-left: -50px;
                                    margin-right: -50px;
                                }
                            }
                        }
                        &:first-child {
                            ul {
                                margin-right: 30px;
                                &:first-child {
                                    li:not(:first-child):not(:last-child) {
                                        margin-right: 0px;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            ul {
                                margin-left: 30px;
                                &:first-child {
                                    li:not(:first-child):not(:last-child) {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } // Contact 
    #contact {
        .contact-form {
            width: 80%;
            margin: auto;
        }
    } // Contact Info 
    #contactInfo {
        height: 350px;
        img {
            position: absolute;
            top: 0;
            width: auto;
            height: 500px;
            margin: auto;
            z-index: 0;
            &.show {
                bottom: 0;
            }
        }
    } // Intro
    #intro {
        &.style2 {
            .fixed-section {
                .jumbotron {
                    .scroll-bottom {
                        p {
                            margin: 20px auto;
                        }
                    }
                    .download-btns {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    } // Error  and Coming soon pages
    #comingSoon,
    #errorPage {
        section {
            margin-top: -40px;
        }
    }
}

/* Large devices (desktops, less than 1200px) */

@include media-breakpoint-down(lg) {
    // ImagesPanel
    .home .imgPanel img {
        margin: 0 -60px;
    } // Download
    #download {
        .section-title {
            margin-bottom: 50px;
        }
        .btn {
            margin-bottom: 20px;
        }
    } // Solid demos 
    body[class*=solid] {
        #loader {
            &:not(.hide) {
                &::before {
                    background-position: bottom;
                    transform: rotate(90deg)translate(20%);
                    top: inherit;
                }
            }
            .text {
                left: 0;
                right: 0;
            }
        }
        #intro.show {
            .fixed-section {
                .jumbotron {
                    &::before {
                        background-position: bottom;
                        transform: rotate(90deg)translate(20%);
                        top: inherit;
                    }
                }
            }
        }
    } // Intro 
    #intro {
        #extra-info {
            .right {
                right: 0;
                left: 0;
                transform: none;
                bottom: 200px;
                top: inherit;
                a {
                    background: transparent;
                    color: lighten($color-gray, 20%);
                    border: 2px solid lighten($color-gray, 20%);
                    border-radius: 50%;
                    font-size: 25px;
                    padding: 9px;
                    width: 60px;
                    height: 60px;
                    display: block;
                    margin: auto;
                    span {
                        display: none;
                    }
                    i {
                        color: lighten($color-gray, 20%);
                        -webkit-text-fill-color: lighten($color-gray, 20%);
                        &:before {
                            content: '\f078'
                        }
                    }
                }
            }
        }
    } // Blogs
    #blogs {
        &.masonry-blogs {
            .card-columns {
                column-count: 2;
            }
        }
    } // Single Blog
    #blogSidebar .latestPosts .post h6.title,
    #blogSidebar .latestPosts .post h6.section-title {
        font-size: 11px;
    }
}

/* Medium devices (tablets, 768px and up) */

@include media-breakpoint-up(md) {
    // Screenshots 
    #screenshots {
        // Slider 
        #screenshots-slider {
            .carousel-inner {
                .carousel-item {
                    img {
                        height: 490px;
                        width: auto;
                    }
                }
            }
        }
    } // Intro
    #intro {
        .fixed-section {
            .jumbotron {
                .scroll-bottom {
                    @include bounceOut();
                }
            }
            &:hover {
                .jumbotron {
                    .scroll-bottom {
                        @include bounceIn();
                    }
                }
            }
        }
    } // About
    #about {
        &.style2 {
            .about-features li {
                >div {
                    .title {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

/*  Medium devices (tablets, less than 992px) */

@include media-breakpoint-down(md) {
    // Basic Rules 
    .paragraph {
        padding: 10px 3px;
    }
    .card {
        margin: 20px auto;
    }
    form {
        &.form-inline {
            .input-group {
                width: 90%;
            }
        }
    }
    .small-section {
        .section-title {
            margin-bottom: 30px;
        }
    } // Navbar 
    #navbar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    margin-top: 5px;
                    text-align: center;
                }
                .btn {
                    width: 60%;
                    margin: 5px auto;
                }
            }
            &.download-btns {
                flex-flow: row;
                justify-content: center;
                clear: both;
            }
        }
    } // Intro 
    #intro {
        height: 1024px;
        .fixed-section {
            padding: 0 0;
            .jumbotron {
                text-align: center;
                .logo {
                    h1 {
                        font-size: 6rem;
                    }
                }
                p {
                    margin: 40px auto;
                    font-size: 25px;
                }
                .btn {
                    font-size: 14px;
                }
                .imgPanel,
                #intro-slider {
                    display: none;
                }
            }
        }
        #extra-info {
            .right {
                bottom: 24px;
                a {
                    font-size: 20px;
                    padding: 9px;
                    width: 50px;
                    height: 50px;
                }
            }
        }
    } // Features 
    #features {
        .features-list {
            .row {
                [class*="col"] {
                    ul {
                        &:first-child {
                            *:not(i) {
                                text-align: left;
                            }
                            .row {
                                direction: ltr;
                            }
                        }
                        p {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    } // subscribe
    #subscribe {
        .input-group {
            width: 90%;
            .btn {
                min-width: 50px;
                padding: 8px;
                span {
                    display: none;
                }
            }
        }
    } // Screenshots 
    #screenshots {
        // Slider 
        #screenshots-slider {
            .carousel-inner {
                .carousel-item {
                    img {
                        width: 200px;
                        height: auto;
                    }
                }
            }
        }
    } // Blogs Section 
    #blog {
        .blog-list {
            .card-title {
                font-size: 1.2rem;
            }
            .card-subtitle {
                font-size: .8rem;
            }
        }
    } // Testimonials 
    #testimonials {
        min-height: 300px;
    } // Contact Info 
    #contactInfo {
        text-align: center;
        ul {
            li {
                i {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    } // Statistics 
    #statistics {
        * {
            text-align: center;
        }
        .col-xl-6 {
            margin: 21px 0;
        }
    }
    body {
        >#header {
            #navbar {
                &:not(.scrolling) {
                    .mask {
                        opacity: .9;
                    }
                }
            }
        }
        &.black-solid-background-demo,
        &[class*=particles] {
            >#header {
                #navbar {
                    &:not(.scrolling) {
                        .mask {
                            background: $color-black;
                        }
                    }
                }
            }
        } // Intro
        &[class*="solid"] {
            #intro.show {
                .fixed-section {
                    .jumbotron {
                        &::before {
                            background-position: bottom;
                            transform: rotate(90deg)translate(20%);
                            top: inherit;
                        }
                    }
                }
            }
        }
    } // blogs
    #blogs {
        &.masonry-blogs {
            .card-columns {
                column-count: 2;
            }
        }
    } // Single Blog
    #blogSidebar .latestPosts .post h6.title,
    #blogSidebar .latestPosts .post h6.section-title {
        font-size: 13px;
    }
    #blogSidebar .latestPosts .media-body {
        align-self: center;
    }
    #blogSidebar .latestPosts .media-body p {
        margin-bottom: 0;
    } // InnerPageTitle 
    #innerPageTitle {
        min-height: 130px;
        margin-top: 56px;
        .title {
            padding: 0px;
        }
        &+section {
            padding: 20px 0;
        }
    } // Call To Action
    #callToAction {
        .section-title {
            text-align: center !important;
        }
    } // Download
    #download {
        .section-title {
            text-align: center !important;
        }
    }
    #comingSoon,
    #errorPage {
        section {
            width: 100%;
        }
    } //Solid Demos
    body[class*="solid"] {
        #intro.show {
            .fixed-section {
                .jumbotron {
                    &::before {
                        background-position: bottom;
                        transform: rotate(90deg)translate(20%);
                        top: inherit;
                    }
                }
            }
        } // loader
        #loader:not(.hide):before {
            background-position: bottom;
            transform: rotate(90deg)translate(25%);
            top: inherit;
        } //comingSoon and error pages
        #comingSoon,
        #errorPage {
            &::before {
                background-position: bottom;
                transform: rotate(90deg)translate(28%);
            }
        }
    }
}

/*  Small devices (landscape phones, less than 768px) */

@include media-breakpoint-down(sm) {
    // Basic Rules 
    section.small-section {
        min-height: 360px;
        padding: 40px 0;
    }
    #contactInfo,
    #statistics,
    #download {
        min-height: 480px;
        .download-links .btn {
            font-size: 15px;
        }
    }
    .section-title {
        margin-bottom: 30px;
        .display-4 {
            font-size: 2.4rem;
            font-weight: 300;
        }
        p {
            width: 90%;
            margin: auto;
            font-size: 15px;
        }
    }
    .display-4 {
        font-size: 1.6rem;
    }
    ul {
        padding: 0;
    }
    .paragraph {
        font-size: 15px; // font-weight: 500;
    }
    .logo {
        h1 {
            font-size: 4.4rem;
        }
    }
    body p {
        font-size: 1rem;
    }
    .home .imgPanel img {
        margin: 0 -40px;
    } // SingleBlog 
    #singleBlog {
        .card-title h2 {
            font-size: 22px;
            line-height: 1.5;
        }
        .info li {
            line-height: 1.7;
            margin-bottom: 10px;
        }
        #comments {
            .buttons {
                justify-content: center;
            }
        }
        .aboutAuthor .media {
            flex-flow: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        #comments>div .comment .media {
            flex-flow: column;
            position: relative;
            small.date {
                position: absolute;
                top: 20px;
                right: 0;
            }
            .userImage {
                height: 50px;
                width: 50px;
            }
        }
    } // Intro 
    #intro {
        .fixed-section {
            padding: 0 0;
            .jumbotron {
                padding: 0;
                .logo {
                    h1 {
                        font-size: 4.4rem;
                        span {
                            // text-shadow: none;
                        }
                    }
                }
                p {
                    font-size: 19px;
                    font-weight: 500;
                }
                .imgPanel {
                    width: 80%;
                    margin: 50px auto;
                }
                .download-btns {
                    .list-inline-item,
                    .list-inline-item:not(:last-child) {
                        margin-right: .6rem;
                    }
                }
            }
        }
    } //About 
    #about {
        .about-features {
            * {
                text-align: center;
            }
        }
        .imgPanel img {
            margin: 0px -90px;
        }
    } // Features 
    #features {
        .features-list {
            .row {
                [class*="col"] {
                    ul {
                        *,
                        &:first-child {
                            * {
                                text-align: center !important;
                            }
                        }
                        i,
                        span {
                            display: block;
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
        &.style2 {
            .features-list {
                ul {
                    * {
                        text-align: center;
                    }
                }
            }
        }
    } // Testimonials 
    #testimonials {
        #testimonials-slider {
            .carousel-inner {
                .carousel-item {
                    .carousel-caption {
                        width: 100%;
                        .lead {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    } // ShowCase
    #showcase {
        .showcase-list {
            .row {
                margin-top: 40px;
                .title {
                    margin-bottom: 10px;
                }
                .paragraph {
                    font-size: 15px;
                }
            }
        }
    } // Coming Soon 
    #comingSoon {
        p {
            font-size: 18px;
        }
        #countDown {
            >div {
                width: 100px;
                height: 100px;
                p {
                    font-size: 10px;
                    strong {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
    #comingSoon,
    #errorPage {
        position: relative; // subscribe section of coming soon and 404 error page 
        #subscribe {
            margin-bottom: 65px;
            .input-group {
                width: 100%;
            }
            #mc_embed_signup {
                padding: 0;
                div.mce_inline_error {
                    top: 50px;
                    width: 95%;
                }
            }
        }
        &+footer {
            position: relative;
            bottom: 0;
            background: $color-black;
            body[class*="solid"] & {
                background: #1b161f;
            }
        }
    } // Error Page 
    #errorPage {
        min-height: 778px;
        .error-icon {
            font-size: 100px;
            width: 240px;
            height: 240px;
        }
    } // masonry-blogs 
    #blogs.masonry-blogs {
        .col-xl-9 .card-columns {
            column-count: 1;
        }
        .card-columns {
            column-count: 1;
        }
    } // Statistics 
    #statistics {
        .col-xl-6 {
            margin: 10px 0;
        }
    } //prices
    #prices {
        .plan {
            width: 93%;
            margin: 0 auto 20px;
        }
    } //blogSidebar
    #blogSidebar form.form-inline .input-group {
        width: 100%;
    }
    body[class*="solid"] {
        #intro.show {
            .fixed-section {
                .jumbotron {
                    &::before {
                        background-position: bottom;
                        transform: rotate(90deg)translate(60%);
                        top: inherit;
                    }
                }
            }
        } // loader
        #loader:not(.hide):before {
            background-position: bottom;
            transform: rotate(90deg)translate(60%);
            top: inherit;
        } //comingSoon and error pages
        #comingSoon,
        #errorPage {
            overflow: hidden;
            &::before {
                background-position: bottom;
                transform: rotate(90deg) translate(66%);
            }
        }
    }
}

/* landscape */

@media(max-width:990px) and (orientation: landscape) {
    // Basic Rules
    section.small-section {
        min-height: 300px;
        padding: 30px 0;
    }
    .container {
        width: 100%;
    }
    section {
        width: 100%;
    }
    #innerPageTitle+section {
        width: 100%;
    }
    .section-title {
        margin-bottom: 30px;
        .display-4 {
            font-size: 2.6rem;
            font-weight: 300;
        }
        p {
            width: 90%;
            margin: auto;
            font-size: 16px;
        }
    }
    .display-4 {
        font-size: 1.6rem;
    }
    .paragraph {
        font-size: 15px; // font-weight: 500;
    }
    .logo {
        h1 {
            font-size: 4.4rem;
        }
    } // intro 
    #intro {
        #extra-info {
            .right {
                bottom: 15px;
                a {
                    font-size: 18px;
                    padding: 6px 0;
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .fixed-section .jumbotron {
            .scroll-bottom {
                bottom: 15px;
                p {
                    margin: 10px 0;
                    a {
                        font-size: 18px;
                        padding: 6px 0;
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .logo {
                h1 {
                    font-size: 4rem;
                }
            }
            p {
                font-size: 17px;
                font-weight: 500;
                margin: 25px auto;
            }
        }
    } // Showcase
    #showcase .showcase-list .row .imgPanel {
        width: 30%;
    }
    .col-xl-6.col-lg-6.col-md-6.col-sm-12.col-xs-12 {
        flex: 0 0 50%;
    } // Statistics 
    #statistics {
        .col-xl-6 {
            margin: 20px 0;
        }
    } // subscribe
    #subscribe {
        .input-group {
            width: 70%;
            .btn {
                min-width: 50px;
                padding: 8px;
                span {
                    display: none;
                }
            }
        }
    } // Coming Soon 
    #comingSoon {
        p {
            font-size: 18px;
        }
        #countDown {
            >div {
                width: 100px;
                height: 100px;
                p {
                    font-size: 10px;
                    strong {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
    #comingSoon,
    #errorPage {
        position: relative; // subscribe section of coming soon and 404 error page 
        #subscribe {
            margin-bottom: 65px;
            .input-group {
                width: 80%;
            }
            #mc_embed_signup {
                padding: 0;
                div.mce_inline_error {
                    top: 50px;
                    width: 75%;
                }
            }
        }
        &+footer {
            position: relative;
            bottom: 0;
            background: $color-black;
            body[class*="solid"] & {
                background: #1b161f;
            }
        }
    } // Error Page 
    #errorPage {
        min-height: 778px;
        .error-icon {
            font-size: 100px;
            width: 240px;
            height: 240px;
        }
    } //singleBlog
    #singleBlog {
        .card-title h2 {
            font-size: 25px;
            line-height: 1.5;
        }
        .info li {
            line-height: 1.7;
            margin-bottom: 10px;
        }
    } //blogSidebar
    #blogSidebar form.form-inline .input-group {
        width: 100%;
    }
    body[class*="solid"] {
        #intro.show {
            .fixed-section {
                .jumbotron {
                    &::before {
                        background-position: center;
                        transform: rotate(0deg)translate(100px);
                    }
                }
            }
        } // loader
        #loader {
            .text {
                left: 55px;
                margin: auto;
                justify-content: flex-start;
            }
            &:not(.hide)::before {
                background-position: center;
                transform: rotate(0deg)translate(100px);
            }
        }
        #comingSoon,
        #errorPage {
            overflow: hidden;
            &::before {
                background-position: center;
                transform: rotate(0deg)translate(100px);
            }
        }
    }
}

/* For  Safari Browsers */

@media screen and (-webkit-min-device-pixel-ratio:0) {
    ::i-block-chrome,
    .mask {
        opacity: .9;
    }
}

/* Firefox Browser*/

@-moz-document url-prefix() {
    .home .imgPanel:hover img {
        margin: 0 -70px;
    }
    body[class*=solid] {
        #intro.show .fixed-section .jumbotron::before,
        #errorPage::before,
        #comingSoon::before,
        #loader:not(.hide):before {
            background-image: url($svg-background-animated-firefox);
        }
    }
}

/* Internet Explorer Browser*/

@mixin IEStyle() {
    .mask {
        opacity: .9;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    @include IEStyle();
}

@media screen and (min-width:0\0) {
    @include IEStyle();
}

@supports (-ms-accelerator:true) {
    @include IEStyle();
}

@supports (-ms-ime-align:auto) {
    @include IEStyle();
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    @include IEStyle();
}